<template>
  <div class="logOperation">
    <el-card>
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-select v-model="dataForm.status" placeholder="状态" clearable size="small">
            <el-option label="失败" :value="0"></el-option>
            <el-option label="成功" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.requestParams" placeholder="请输入请求参数" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" size="small" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="exportHandle()" size="small">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" border @sort-change="dataListSortChangeHandle" style="width: 100%;">
        <el-table-column prop="creatorName" label="用户名" header-align="center" align="center"></el-table-column>
        <el-table-column prop="operation" label="用户操作" header-align="center" align="center"></el-table-column>
        <el-table-column prop="requestUri" label="请求URI" header-align="center" align="center"></el-table-column>
        <el-table-column prop="requestMethod" label="请求方式" header-align="center" align="center"></el-table-column>
        <el-table-column prop="requestParams" label="请求参数" header-align="center" align="center" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="requestTime" label="请求时长" sortable="custom" header-align="center" align="center">
          <template slot-scope="scope">
            {{ `${scope.row.requestTime}ms` }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" sortable="custom" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small" type="danger">失败</el-tag>
            <el-tag v-else size="small" type="success">成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="ip" label="操作IP" header-align="center" align="center"></el-table-column>
        <el-table-column prop="userAgent" label="User-Agent" header-align="center" align="center" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" sortable="custom" header-align="center" align="center" width="180"></el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/log/operation',
        getDataListIsPage: true,
        exportURL: '/sys/log/operation/export'
      },
      dataForm: {
        status: '',
        requestParams: ''
      },
      dataList: [],               // 数据列表
      dataListLoading: false,     // 数据列表，loading状态
      order: '',                  // 排序，asc／desc
      orderField: '',             // 排序，字段
      page: 1,                    // 当前页码
      limit: 10,                  // 每页数
      total: 0                   // 总条数
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http.get(
        this.mixinViewModuleOptions.getDataListURL,
        {
          params: {
            order: this.order,
            orderField: this.orderField,
            page: this.mixinViewModuleOptions.getDataListIsPage ? this.page : null,
            limit: this.mixinViewModuleOptions.getDataListIsPage ? this.limit : null,
            ...this.dataForm
          }
        }
      ).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          this.total = 0
          return this.$message.error(res.msg)
        }
        this.dataList = this.mixinViewModuleOptions.getDataListIsPage ? (res.data.list ? res.data.list : res.data.records) : res.data
        this.total = this.mixinViewModuleOptions.getDataListIsPage ? res.data.total * 1 : 0
      }).catch(() => {
        this.dataListLoading = false
      })
    },
    // 排序
    dataListSortChangeHandle (data) {
      if (!data.order || !data.prop) {
        this.order = ''
        this.orderField = ''
        return false
      }
      this.order = data.order.replace(/ending$/, '')
      this.orderField = data.prop.replace(/([A-Z])/g, '_$1').toLowerCase()
      this.getDataList()
    },
    // 分页, 每页条数
    pageSizeChangeHandle (val) {
      this.page = 1
      this.limit = val
      this.getDataList()
    },
    // 分页, 当前页
    pageCurrentChangeHandle (val) {
      this.page = val
      this.getDataList()
    },
    // 导出
    exportHandle () {
      this.$message.success('开始导出')
      this.$http({
        method: 'get',
        url: this.mixinViewModuleOptions.exportURL,
        params: this.dataForm,
        responseType: 'blob'
      }).then((res) => {
        const _res = res.data
        let blob = new Blob([_res])
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, '操作日志.xlsx')
        } else {
          let downloadElement = document.createElement('a')
          let href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '操作日志.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .logOperation {
    .el-pagination {
      text-align: right;
      margin-top: 6px;
    }
  }
</style>
